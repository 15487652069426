import { Link } from "react-router-dom";
import Floating from "../Components/Floating";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import ProductImg1 from '../assets/images/hero-4.png';
import ProductImg2 from '../assets/images/hero-5.png';
import ProductImg3 from '../assets/images/hero-7.png';
import ProductImg4 from '../assets/images/hero-3.png';
import ProductImg5 from '../assets/images/hero-8.png';
import ProductImg6 from '../assets/images/hero-1.png';

const Solutions = () => {
  // Card Component
  const CardProduct = [
    {
      title: "Lorem Ipsum",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit, harum!",
      image: ProductImg1,
      link: '/DigitalLending',
    },
    {
      title: "Lorem Ipsum",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit, harum!",
      image: ProductImg2,
      link: '/CollectionMS',
    },
    {
      title: "Lorem Ipsum",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit, harum!",
      image: ProductImg3,
      link: '/CustodyMS',
    },
    {
      title: "Lorem Ipsum",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit, harum!",
      image: ProductImg4,
      link: '/SLIKRobotic',
    },
    {
      title: "Lorem Ipsum",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit, harum!",
      image: ProductImg5,
      link: '/CreditRecovery',
    },
    {
      title: "Lorem Ipsum",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit, harum!",
      image: ProductImg6,
      link: '/WhyChooseUs',
    },
  ];

  return (
    <>
      <Navbar />
      <Floating />
      <div className="w-full h-auto bg-gradient-to-r from-blue-500 to-sky-500">
        <div className="container mx-auto p-2 2xl:p-8">
          <div className="flex justify-center items-center py-16">
            <h1 className="text-white font-Montserrat text-[2rem] rounded-lg p-2 lg:text-[2.5rem]">Our Product</h1>
          </div>
          <div className="flex justify-center items-center mb-8">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {CardProduct.map((card, index) => (
                <Link to={card.link} key={index} className="text-black">
                  <div
                    className="bg-white/70 hover:bg-white/80 w-72 md:w-100 min-h-[100%] shadow-indigo-400 hover:shadow-emerald-400 p-6 rounded-lg shadow-md cursor-pointer transition-transform hover:scale-105 duration-300"
                  >
                    <img
                      src={card.image}
                      alt={card.title}
                      className="w-72 h-40 object  mb-4 rounded-md"
                    />
                    <h2 className="text-[1rem] font-bold mb-2 flex justify-center">{card.title}</h2>
                    <p className="text-gray-600 flex text-[1rem] text-justify p-2">{card.description}</p>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Solutions;
