import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { Spinner } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../Components/Navbar';
import OfficeImg from '../assets/Icons/city.svg';
import ContactImg from '../assets/Icons/whatsappHP.png';
import EmailImg from '../assets/Icons/envelope.svg';
import CardContact from '../Components/CardContact';
import Floating from '../Components/Floating';

const Contact = () => {
  const [loading, setLoading] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);
  const form = useRef();

  const sendEmail = async (e) => {
    e.preventDefault();
    setLoading(true);

    const name = e.target.user_name.value;
    const email = e.target.user_email.value;
    const message = e.target.message.value;

    if (!name || !email || !message) {
      toast.warning('Please fill out all fields.');
      setLoading(false);
      return;
    }

    try {
      const result = await emailjs.sendForm('service_ie57cw1', 'template_wrjkbxv', form.current, '8XzAZA8etder0LXyX');
      console.log(result.text);
      toast.success('Your email has been sent successfully!');
      form.current.reset();
      setIsSubmited(true);
      setTimeout(()=> setIsSubmited(false), 300000); //5 menit
    } catch (error) {
      console.log(error.text);
      toast.error('Oops! Something went wrong. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const cards = [
    {
      image: OfficeImg,
      title: "Office",
      path: "https://www.google.com/maps/preview",
    },
    {
      image: ContactImg,
      title: "Contact",
      path: "https://wa.me//62888888888",
    },
    {
      image: EmailImg,
      title: "Email",
      path: "mailto:example@gmail.com",
    },
  ];

  return (
    <>
      <Navbar />
      <Floating />
      <div className="w-full h-full lg:h-screen p-8 2xl:p-16 bg-gradient-to-r from-blue-500 to-sky-500 lg:overflow-y-hidden">
        <div className="container mx-auto">
          <div className="grid lg:grid-cols-2 gap-8 py-8 lg:py-8">
            {/* heading */}
            <div className="w-full h-auto md:p-8 2xl: text-white">
              <div className="justify-center items-center space-y-4 py-8 lg:py-2">
                <h1 className="uppercase font-Roboto text-center text-[1.2rem] md:text-[1.8rem] lg:text-[1.3rem] flex justify-center">
                  Thank you for using our services.
                </h1>
                <p className="font-Poppins text-center text-[0.8rem] md:text-[1.3rem] lg:text-[1rem]">
                  You can reach us by phone, email, or through our website's contact form. We're dedicated to responding promptly and professionally to your inquiries. Our extended business hours ensure accessibility to accommodate your schedule.
                </p>

                {/* card */}
                <div className="flex justify-center items-center">
                  <div className="grid text-black grid-cols-3 gap-16 md:flex md:py-8">
                    {cards.map((card) => (
                      <CardContact
                        key={card.title}
                        title={card.title}
                        image={card.image}
                        path={card.path}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {/* inputan contact */}
            <div className="lg:py-12">
              <div className="w-full h-[500px] 2xl:h-[550px] lg:w-100 lg:ml-auto p-4 md:p-8 2xl:p-8 bg-sky-50 rounded-xl border-2 shadow-lg">
                <h1 className="flex justify-center font-Montserrat md:text-[1.3rem] mb-4">The best way to contact us.</h1>
                <form ref={form} onSubmit={sendEmail}>
                  {/* name */}
                  <h1 className='mb-2'>Name</h1>
                  <label htmlFor="name">
                    <input
                      className="w-full border-2 border-sky-400 p-2 md:text-[1.2rem] rounded-lg"
                      type="text"
                      placeholder="John Cenna"
                      name="user_name"
                    />
                  </label>
                  {/* email */}
                  <h1 className='mt-4 mb-2'>Email</h1>
                  <label htmlFor="email">
                    <input
                      className="w-full border-sky-400 p-2 border-2 rounded-lg text-[1.2rem]"
                      type="email"
                      placeholder="example@example.com"
                      name="user_email"
                    />
                  </label>
                  {/* message */}
                  <label htmlFor="message">
                    <textarea
                      className="mt-8 w-full border-2 rounded-lg text-[1.2rem] max-h-36 2xl:max-h-44 border-sky-400 p-4"
                      placeholder="Enter a message"
                      name="message"
                      id=""
                      cols="46"
                      rows="5"
                    ></textarea>
                  </label>
                  {/* button */}
                  <button
                    className="mt-2 text-[1rem] flex items-end ml-auto bg-emerald-500 hover:bg-emerald-600 p-2 rounded-lg text-white md:text-[1.2rem] transform hover:scale-105 transition-transform duration-300"
                    type="submit"
                    disabled={loading || isSubmited}
                  >
                    {loading ? (
                      <Spinner animation="border" role="status" size="sm" className="mr-2">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    ) : null}
                    {loading ? 'Sending...' : 'Send'}
                  </button>
                </form>
              </div>
            </div>

            {/* Icons Saat di mobile */}
            <h1 className="flex justify-center text-[0.8rem] font-Poppins text-white md:hidden">Service availability and operating hours.</h1>
            <div className="flex justify-center md:hidden">
              <div className="grid grid-cols-3 gap-6 w-32">
                <button>
                  <a href="https://maps.app.goo.gl/UcZ2DDpRJUNWocpg7" target="_blank" rel="noreferrer">
                    <img src={OfficeImg} alt="" />
                  </a>
                </button>
                <button>
                  <a href="https://wa.me//62888888888" target="_blank" rel="noreferrer">
                    <img src={ContactImg} alt="" />
                  </a>
                </button>
                <button>
                  <a href="mailto:example@gmail.com" target="_blank" rel="noreferrer">
                    <img src={EmailImg} alt="" />
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Contact;
