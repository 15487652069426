import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import AboutGambar from "../assets/images/hero-2.png";
import CardImage1 from "../assets/images/FotoCowok.jpg";
import CardImage3 from "../assets/images/FotoCewek2.jpg";
import Floating from "../Components/Floating";
import checklistImg from '../assets/Icons/checklist.png';
import AOS from "aos";
import "aos/dist/aos.css"
import { useEffect } from "react";


const About = () => {
  useEffect (()=> {
    AOS.init({
      duration:500,
      easing:"ease-in-out",
      once:false,
    })
  })
  // Placeholder data for cards
  const cards = [
    {
      title: "Andika Wirawan, S.T.",
      description: "- President Director -",
      image: CardImage1,
    },
    {
      title: "Hendra Susanto, M.Kom.",
      description: "- IT Director -",
      image: CardImage1,
    },
    {
      title: "Ahmad Ramadhan, S.Kom.",
      description: "- Manager Developer -",
      image: CardImage1,
    },
    {
      title: "Rizky Pratama, S.Kom.",
      description: "- Java Developer -",
      image: CardImage1,
    },
    {
      title: "Bayu Setiawan, S.T.",
      description: "- Principal Engineer -",
      image: CardImage1,
    },
    {
      title: "Dika Syahputra, S.Kom.",
      description: "- Front End Developer -",
      image: CardImage1,
    },
    {
      title: "Putri Wulandari, S.E.",
      description: "- Finance Staff -",
      image: CardImage3,
    },
    {
      title: "Dita Maharani, S.Psi.",
      description: "- HRD -",
      image: CardImage3,
    },
  ];

  return (
    <>
      <Navbar />
      <Floating />
      <div className="w-full h-auto text-white bg-gradient-to-r from-blue-500 to-sky-500">
        <div className="container mx-auto py-16">
          <div className="py-2">
            <div className="grid grid-cols-1 md:grid-cols-2 justify-between ml-auto">
              {/* heading */}
              <div className="md:pr-8 p-8 order-2 md:order-1" data-aos="fade-right">
                <h1 className="text-[1.3rem] xl:text-[1.3rem] 2xl:text-[1.5rem] text-center md:text-start font-Roboto mb-2 flex justify-start xl:pl-6">Lorem ipsum dolor sit amet consectetur.</h1>
                <p className="flex justify-center font-Poppins items-center text-start text-[1rem] md:text-[1.1rem] xl:pl-6 xl:pr-6 xl:text-[1rem] 2xl:text-[1.2rem]">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolore alias unde ea consequuntur aspernatur, suscipit dolor dignissimos beatae explicabo doloremque?
                </p>
                <h1 className="text-start font-Montserrat xl:pl-6 mt-8 text-[1.2rem] text-slate-200">Lorem ipsum, dolor sit amet consectetur adipisicing.</h1>
                <div className="flex justify-start items-center">
                <div className="grid grid-cols-1 text-[0.8rem] md:text-[1rem] 2xl:text-[1rem] font-Poppins xl:pl-6 mt-2 space-y-2">
                  <div className=""><p className="flex"><span><img src={checklistImg} alt="" className="w-4 mr-2 flex justify-center items-center 2xl:mt-1" /></span>Lorem ipsum dolor sit.</p></div>
                  <div className=""><p className="flex"><span><img src={checklistImg} alt="" className="w-4 mr-2 flex justify-center items-center 2xl:mt-1" /></span>Lorem ipsum dolor sit.</p></div>
                  <div className=""><p className="flex"><span><img src={checklistImg} alt="" className="w-4 mr-2 flex justify-center items-center 2xl:mt-1" /></span>Lorem ipsum dolor sit.</p></div>
                  <div className=""><p className="flex"><span><img src={checklistImg} alt="" className="w-4 mr-2 flex justify-center items-center 2xl:mt-1" /></span>Lorem ipsum dolor sit.</p></div>
                  <div className=""><p className="flex"><span><img src={checklistImg} alt="" className="w-4 mr-2 flex justify-center items-center 2xl:mt-1" /></span>Lorem ipsum dolor sit.</p></div>
                  <div className=""><p className="flex"><span><img src={checklistImg} alt="" className="w-4 mr-2 flex justify-center items-center 2xl:mt-1" /></span>Lorem ipsum dolor sit.</p></div>
                </div>
                </div>
              </div>
              {/* image */}
              <div className="flex justify-center items-center ml-auto w-full order-1">
                <img
                  src={AboutGambar}
                  alt="About Us"
                  className="w-[300px] md:w-[500px] 2xl:w-[500px] rounded-lg md:ml-6" data-aos="fade-left"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="w-full py-8 bg-gray-200">
        <div className="container mx-auto">
          {/* cards */}
          <div className="flex justify-center items-center p-8">
          <h1 className="text-black text-[1.5rem] md:text-[2rem] font-Roboto">Organizational Structure</h1>
          </div>

        <div className="flex justify-center items-center">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
            {cards.map((card, index) => (
              <div
                key={index}
                className="bg-white w-80 md:w-100 h-80 p-6 rounded-lg shadow-md" data-aos="zoom-in-up"
              >
                <img
                  src={card.image}
                  alt={card.title}
                  className="w-full h-44 object-cover mb-4 rounded-md"
                />
                <h2 className="text-xl font-bold mb-2 flex justify-center">{card.title}</h2>
                <p className="text-gray-600 flex justify-center">{card.description}</p>
              </div>
            ))}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default About;
